import { node } from 'prop-types';
import React from 'react';
import {
  Button,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  Image,
} from '@nintendo-of-america/component-library';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import * as S from '@local/components/pages/NewYork/new-york.styles';
import { Link } from '@nintendo-of-america/next';
import { cloudinaryUrl } from '@shared/util';

function Cloudinary(module) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  return `url(${cloudinaryUrl({
    publicId:
      isTablet || !module.secondary.assetPath
        ? module.primary.assetPath
        : module.secondary.assetPath,
    quality: '100',
  })}) center`;
}

const SocialMediaIcons = () => {
  return (
    <S.SocialMediaGrid>
      <Link href="https://instagram.com/nintendonyc">
        <S.FlexA>
          <InstagramIcon />
        </S.FlexA>
      </Link>
      <Link href="https://www.facebook.com/NintendoNYC">
        <S.FlexA>
          <FacebookIcon />
        </S.FlexA>
      </Link>
      <Link href="https://twitter.com/NintendoNYC">
        <S.FlexA>
          <TwitterIcon />
        </S.FlexA>
      </Link>
    </S.SocialMediaGrid>
  );
};

const NewYorkWrap = ({ children, module }) => {
  const navItems = module.modules.map((item) => ({
    title: item.label,
    url: item.url,
  }));

  return (
    <>
      <S.ImageSection
        background={Cloudinary(module.modules[0])}
        constrained
        small
      >
        <S.LeftLogoContainer>
          <Image
            alt="Nintendo New York"
            assetPath={cloudinaryUrl({
              publicId: '/ncom/en_US/new-york/LeftLogoHeader.png',
            })}
          />
        </S.LeftLogoContainer>
        <S.MobileCenterLogoContainer>
          <S.RoundedMobileLogo
            assetPath={cloudinaryUrl({
              publicId: '/ncom/en_US/new-york/MobileLogo.png',
            })}
          />
        </S.MobileCenterLogoContainer>
      </S.ImageSection>

      <S.NavBar>
        <S.LinkFarm>
          {navItems.slice(1).map((btn) => (
            <Button
              href={btn.url}
              round
              key={btn.title}
              buttonWidth="auto"
              variant="secondary"
            >
              {btn.title}
            </Button>
          ))}
        </S.LinkFarm>
        <SocialMediaIcons />
      </S.NavBar>

      {children}
    </>
  );
};

NewYorkWrap.propTypes = {
  children: node,
};

export default NewYorkWrap;
