import styled from 'styled-components';
import { Heading, Image } from '@nintendo-of-america/component-library';
import { Section } from '@local/components';

export const ImageSection = styled(Section)`
  padding: 0;
  height: 520px;
  background-position: center;
  position: relative;
  overflow: hidden;
`;

export const LeftLogoContainer = styled.div`
  display: none;
  ${({ theme }) => theme.mediaQuery.desktop} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;

    img {
      height: 520px;
      width: 413.19px;
    }
  }
`;

export const MobileCenterLogoContainer = styled.div`
  display: grid;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  ${({ theme }) => theme.mediaQuery.desktop} {
    display: none;
  }
`;

export const FaqImageSection = styled(ImageSection)`
  margin-bottom: -3.9rem;
`;

export const WavySection = styled(Section)`
  --mask: radial-gradient(
        24.38px at 50% calc(100% - 34.4px),
        #000 99%,
        #0000 101%
      )
      calc(50% - 32px) 0/64px 100%,
    radial-gradient(24.38px at 50% calc(100% + 18.4px), #0000 99%, #000 101%)
      50% calc(100% - 16px) / 64px 100% repeat-x;

  -webkit-mask: var(--mask);
  mask: var(--mask);
  margin-bottom: -32px;
  overflow: hidden;
`;

export const WavySectionIndex = styled(WavySection)`
  z-index: ${(props) => props.zindex};
  height: ${(props) => props.height};
  background-size: ${(props) => (props.backgroundTiled ? 'auto' : 'cover')};
`;

export const WavyImageSection = styled(WavySectionIndex)`
  background-position: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
  ${({ theme }) => theme.mediaQuery.tablet} {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  margin-top: 1rem;
  padding-top: 1rem;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: ${({ theme }) => theme.color.black};
  border-radius: 2rem;

  ${({ theme }) => theme.mediaQuery.tablet} {
    flex-direction: row;
    border-radius: 5rem;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;

    & > div:nth-child(1) {
      margin-left: auto;
    }

    & > div:nth-child(3) {
      margin-right: auto;
    }
  }
`;

export const Header = styled(Heading)`
  color: #fff;
  text-align: center;
  grid-column-start: 2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 3rem;
  margin-right: 3rem;
`;

export const MarioNYLogo = styled(Image)`
  width: 30%;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
`;

export const SocialMediaGrid = styled.div`
  display: grid;
  align-content: center;
  align-self: center;
  grid-template-columns: 3em 3em 3em;
  gap: 1em;
  padding: 0 5em;
  ${({ theme }) => theme.mediaQuery.tablet} {
    padding: 0;
    gap: 2em;
    grid-template-columns: 2em 2em 2em;
  }
`;

export const DecoratorSize = styled(Image)`
  width: 10rem;
`;

export const Decorator = styled(DecoratorSize)`
  padding: 0;
`;

export const SectionEight = styled(Section)`
  margin-bottom: -3.9rem;
`;

export const SocialMediaEmbedGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  max-width: 100%;
  overflow: hidden;

  a,
  iframe {
    max-width: 100%;
  }
  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FacebookContainer = styled.div`
  display: grid;
  height: 300px;
  background-color: white;
  justify-items: center;
  align-items: center;
  border-radius: 15px;
`;

export const FaqHeaderContainer = styled.div`
  display: grid;
  margin: auto;
  padding: 0;
  justify-items: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 2fr 1fr;

    > div:nth-child(1) {
      display: grid;
      width: 2rem;
      justify-self: end;
    }

    > div:nth-child(3) {
      display: grid;
      width: 2rem;
      justify-self: start;
    }
  }
`;

export const FaqHeaderSection = styled(HeaderSection)`
  background-color: ${({ theme }) => theme.color.black};
  padding-top: 0;
`;

export const MapGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.mediaQuery.desktop} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FlexA = styled.a`
  display: flex;
  justify-content: center;
`;

export const LinkFarm = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.5em;
  padding-bottom: 1em;
  ${({ theme }) => theme.mediaQuery.desktop} {
    flex-direction: row;
    padding: 0;
    gap: 1em;
  }
`;

export const RoundedMobileLogo = styled(Image)`
  img {
    border-radius: 1rem;
  }
`;
