import React from 'react';
import { shape } from 'prop-types';
import { Divider } from '@nintendo-of-america/component-library';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { AnyUp } from '@local/components';
import { cloudinaryUrl } from '@shared/util';

import { PAGE_TYPES } from '@nintendo-of-america/contentful-api/constants';
import getPageBySlug from '@local/lib/contentful/api/getPageBySlug';
import getPageProps from '@local/lib/api/getPageProps';
import NewYorkWrap from '@local/components/pages/NewYork/NewYorkWrap/NewYorkWrap';
import * as S from '@local/components/pages/NewYork/new-york.styles';
import { SectionItem } from '@local/components/pages/PMP';

export async function getServerSideProps({ res, locale }) {
  const page = await getPageBySlug({
    slug: '/new-york/',
    locale,
    pageContentType: PAGE_TYPES.MODULAR,
  });

  return getPageProps({
    page,
    analytics: {
      pageType: 'New York',
      pageName: 'Nintendo New York',
      pageCategory: 'New York',
    },
    res,
  });
}

function Cloudinary(module) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  return `url(${cloudinaryUrl({
    publicId:
      isTablet || !module.secondary.assetPath
        ? module.primary.assetPath
        : module.secondary.assetPath,
    quality: '100',
  })}) center`;
}

//returns path: ncom/en_US/new-york/warp-pipe-right
function CloudinaryPath(module) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  return `${
    isTablet || !module.secondary.assetPath
      ? module.primary.assetPath
      : module.secondary.assetPath
  }`;
}

const GoogleMaps = () => {
  return (
    <iframe
      title="Nintendo New York store location"
      className="maps-widget"
      width="100%"
      height="100%"
      src="https://maps.google.com/maps?client=safari&oe=UTF-8&ie=UTF8&q=nintendo+world+store&fb=1&gl=us&t=m&hq=nintendo+world+store&ll=40.7587,-73.978972&spn=0.008452,0.023131&z=15&iwloc=A&output=embed"
    ></iframe>
  );
};

function HeaderSectionTemplate({ header, left, right }) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);

  return (
    <S.HeaderSection>
      <S.Decorator assetPath={left} />

      <S.Header variant="h1">{header}</S.Header>
      {isTablet ? <S.Decorator assetPath={right} /> : null}
    </S.HeaderSection>
  );
}

function StarDivider({ image }) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);

  return (
    <>
      <Divider
        height={88}
        width={287}
        imgSrc={image}
        variant={isTablet ? 'repeat' : 'scale'}
      />
    </>
  );
}

function FacebookEmbed() {
  return (
    <iframe
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FNintendoNYC%2F&tabs=timeline&width=340&height=300&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
      width="340"
      height="300"
      style={{ border: 'none', overflow: 'hidden', margin: '0 auto' }}
      allowFullScreen="true"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    ></iframe>
  );
}

function NewYork({ page }) {
  const { modules } = page.content;

  const chompLeft = CloudinaryPath(modules[modules.length - 1].modules[0]);
  const chompRight = CloudinaryPath(modules[modules.length - 1].modules[1]);
  const warpPipeLeft = CloudinaryPath(modules[modules.length - 1].modules[4]);
  const warpPipeRight = CloudinaryPath(modules[modules.length - 1].modules[5]);

  return (
    <NewYorkWrap module={modules[0]}>
      {/* Section 2: Welcome to NNY */}
      <S.WavySectionIndex
        background={Cloudinary(modules[2].modules[0])}
        constrained
        small
        id={modules[2].deepLink}
        zindex={'10'}
        backgroundTiled
        hide={modules[2].hide}
      >
        <HeaderSectionTemplate
          left={warpPipeLeft}
          header={modules[2].heading}
          right={warpPipeRight}
        />
        <AnyUp reverseContent modules={modules[2].modules.slice(1, 2)} />
        <StarDivider
          image={CloudinaryPath(modules[modules.length - 1].modules[2])}
        />
        <S.MapGrid>
          <SectionItem
            content={modules[2].modules[2].richText}
            options={{ scrim: 'dark', textAlign: 'left' }}
          />
          <GoogleMaps />
        </S.MapGrid>
      </S.WavySectionIndex>

      {/* Section 3: Upcoming Events */}
      <S.WavySectionIndex
        background={Cloudinary(modules[3].modules[0])}
        constrained
        small
        id={modules[3].deepLink}
        zindex={'9'}
        backgroundTiled
        hide={modules[3].hide}
      >
        <HeaderSectionTemplate
          left={chompLeft}
          header={modules[3].heading}
          right={chompRight}
        />
        <AnyUp modules={modules[3].modules.slice(1)} lightScrim />
        {/* </Section> */}
      </S.WavySectionIndex>

      {/* Section 4 */}
      <S.WavyImageSection
        background={Cloudinary(modules[4].modules[0])}
        constrained
        small
        id={modules[4].id}
        zindex={'8'}
        hide={modules[4].hide}
      ></S.WavyImageSection>

      {/* Section 5 */}
      <S.WavySectionIndex
        background={Cloudinary(modules[5].modules[0])}
        constrained
        small
        id={modules[5].deepLink}
        zindex={'7'}
        backgroundTiled
        hide={modules[5].hide}
      >
        <HeaderSectionTemplate
          left={warpPipeLeft}
          header={modules[5].heading}
          right={warpPipeRight}
        />
        <AnyUp modules={modules[5].modules.slice(1)} />
      </S.WavySectionIndex>

      {/* Section 6 */}
      <S.WavySectionIndex
        background={Cloudinary(modules[6].modules[0])}
        constrained
        small
        id={modules[6].deepLink}
        zindex={'6'}
        backgroundTiled
        hide={modules[6].hide}
      >
        <HeaderSectionTemplate
          left={chompLeft}
          header={modules[6].heading}
          right={chompRight}
        />
        <AnyUp modules={modules[6].modules.slice(1)} lightScrim />
      </S.WavySectionIndex>

      {/* Section 7 */}
      <S.WavyImageSection
        background={Cloudinary(modules[7].modules[0])}
        constrained
        small
        id={modules[7].id}
        zindex={'5'}
        hide={modules[7].hide}
      ></S.WavyImageSection>

      {/* Section 8 */}
      <S.SectionEight
        background={Cloudinary(modules[8].modules[0])}
        constrained
        small
        backgroundTiled
        id={modules[8].id}
        hide={modules[8].hide}
      >
        <HeaderSectionTemplate
          header={modules[8].heading}
          right={warpPipeRight}
          left={warpPipeLeft}
        />
        <S.SocialMediaEmbedGrid>
          <a
            className="twitter-timeline"
            data-height="300"
            data-theme="light"
            href="https://twitter.com/NintendoNYC?ref_src=twsrc%5Etfw"
          >
            Tweets by NintendoNYC
          </a>{' '}
          <script async src="https://platform.twitter.com/widgets.js"></script>
          <S.FacebookContainer>
            <FacebookEmbed />
          </S.FacebookContainer>
        </S.SocialMediaEmbedGrid>
        <StarDivider
          image={CloudinaryPath(modules[modules.length - 1].modules[2])}
        />
        <S.MarioNYLogo
          assetPath={CloudinaryPath(modules[modules.length - 1].modules[3])}
        />
      </S.SectionEight>
    </NewYorkWrap>
  );
}

NewYork.propTypes = {
  page: shape({
    content: [{}],
  }),
};

export default NewYork;
